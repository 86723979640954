<template>
  <WeContainer v-if="ready">
    <!-- Title -->
    <h5 class="mb-0">
      <i class="fas fa-bezier-curve mr-2"></i
      >{{ collection.title }} Entegrasyonu
    </h5>
    <hr />
    <!-- Title -->

    <!-- Is Active -->
    <div class="row align-items-center my-4">
      <!-- Label -->
      <div class="col-12 col-lg-2">
        <span class="custom-label">Durum</span>
      </div>
      <!-- Label -->

      <!-- Value -->
      <div class="col-12 col-lg-4">
        <WeSwitch v-model="collection.is_active" v-bind:show-status="true" />
      </div>
      <!-- Value -->
    </div>
    <!-- ./Is Active -->

    <!-- Is Default -->
    <div class="row align-items-center my-4">
      <!-- Label -->
      <div class="col-12 col-lg-2">
        <span class="custom-label">Varsayılan Tahsilat Kuruluşu</span>
      </div>
      <!-- Label -->

      <!-- Value -->
      <div class="col-12 col-lg-4">
        <WeSwitch v-model="collection.is_default" v-bind:show-status="true" />
      </div>
      <!-- Value -->
    </div>
    <!-- ./Is Default -->

    <!-- Is Test -->
    <div class="row align-items-center my-4">
      <!-- Label -->
      <div class="col-12 col-lg-2">
        <span class="custom-label">Test Modu</span>
      </div>
      <!-- Label -->

      <!-- Value -->
      <div class="col-12 col-lg-4">
        <WeSwitch v-model="collection.is_test" v-bind:show-status="true" />
      </div>
      <!-- Value -->
    </div>
    <!-- ./Is Test -->

    <!-- Content -->
    <div
      class="row align-items-center mb-3"
      v-for="(el, i) in collection.elements"
      v-bind:key="i"
    >
      <!-- Label -->
      <div class="col-12 col-lg-2">
        <span class="custom-label">{{ el.label }}</span>
        <span class="text-danger ml-1" v-if="el.required">*</span>
        <i
          class="fas fa-question-circle ml-2"
          v-if="el.hint"
          v-tooltip.top="el.hint"
        ></i>
      </div>
      <!-- Label -->

      <div class="col-12 col-lg-4">
        <WeInput
          v-if="el.form == 'input'"
          class="mb-0"
          v-model="el.value"
          v-bind:type="el.type"
          v-bind:error="$v.collection.elements.$each[i].value.$error"
        />
        <div class="form-group" v-else-if="el.form == 'select'">
          <label class="custom-label">{{ el.label }}</label>
          <v-select
            label="name"
            v-bind:options="el.options"
            v-bind:reduce="(o) => o.id"
            v-model="el.value"
            v-bind:placeholder="el.label"
            size="lg"
          ></v-select>
        </div>
      </div>
    </div>

    <!-- Use Installment -->
    <div class="row align-items-center my-4">
      <!-- Label -->
      <div class="col-12 col-lg-2">
        <span class="custom-label">Taksit Seçenekleri</span>
      </div>
      <!-- Label -->

      <!-- Value -->
      <div class="col-12 col-lg-4">
        <WeSwitch
          v-model="collection.use_installment"
          v-bind:show-status="true"
        />
      </div>
      <!-- Value -->
    </div>
    <!-- ./Use Installment -->

    <div v-if="collection.use_installment">
      <!-- Seperator -->
      <div class="my-5">
        <WeDivSeperator text="Taksit Seçenekleri" />
      </div>
      <!-- ./Seperator -->

      <div class="row align-items-center mb-3">
        <!-- Label -->
        <div class="col-12 col-lg-2">
          <span class="custom-label">Minimum Sepet Tutarı</span>
        </div>
        <!-- Label -->

        <!-- Value -->
        <div class="col-12 col-lg-4">
          <WeInput class="mb-0" v-model="collection.min_installment_amount" />
        </div>
        <!-- Value -->
      </div>

      <!-- Rate Info -->
      <WeCard class="alert-primary mb-3">
        <div class="row align-items-center">
          <div class="col-auto">
            <i class="fas fa-info-circle fa-lg"></i>
          </div>
          <div class="col">
            <b>Oran Açıklamaları</b>
            <ul class="m-0">
              <li>
                0 değeri girildiğinde <b>Peşin</b> fiyatı tabloda
                gösterilecektir.
              </li>
              <li>
                1 veya daha büyük değerler için sepet tutarı üzerinden % olarak
                hesaplama yapılacaktır.
              </li>
            </ul>
          </div>
        </div>
      </WeCard>
      <!-- ./Rate Info -->

      <!-- Rates -->
      <div class="row">
        <div class="col-12 col-lg-2">
          <span class="custom-label">Oranlar (%)</span>
        </div>
        <div class="col">
          <div class="row">
            <div
              class="col-2"
              v-for="rates in collection.rates"
              v-bind:key="rates.installment"
            >
              <WeInput
                name="rate"
                v-model="rates.rate"
                v-bind:prepend="rates.installment"
                v-bind:small-size="true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Rates -->
    </div>
    <!-- Content -->

    <!-- Submit -->
    <WeSubmitButton
      parent-class="text-center"
      v-bind:submit-status="submit"
      v-bind:update="$route.params.id"
      v-on:submit="validateForm"
    />
    <!-- ./Submit -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  name: "Pos",
  data() {
    return {
      ready: false,
      submit: false,
      collection: {},
    };
  },
  validations: {
    collection: {
      elements: {
        $each: {
          value: {
            required: requiredIf(function (data) {
              return data.required && !data.value;
            }),
          },
        },
      },
    },
  },
  methods: {
    ...mapActions("collection", ["update", "getById", "getList"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        this.submit = true;
        this.updateCollection();
      }
    },
    getCollectionById() {
      const id = this.$route.params.id;
      if (id) {
        this.getById({
          id: id,
          onSuccess: (result) => {
            this.collection = result;
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.$router.push("/payments");
      }
    },
    updateCollection() {
      this.update({
        id: this.$route.params.id,
        form: this.collection,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }

          this.submit = false;
        },
      });
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    redirectToList() {
      this.$router.push("/payments");
    },
  },
  computed: {
    ...mapState({
      collectionState: (state) => state.collection,
      shared: (state) => state.shared,
    }),
  },
  mounted() {
    this.getCollectionById();
  },
};
</script>
